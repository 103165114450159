"use client";

import { settings } from "@/app/(app)/dashboard/settings/components/setting-pages";
import { FeedbackWidgetButton } from "@/components/functional/feedback-widget-button";
import { ProfileSettings } from "@/components/functional/profile-settings";
import { ReturnIcon } from "@/components/icons/return-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { type Subscriptions } from "@prisma/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { CreditBadge } from "../credit-badge";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

export function SettingsHeader({
  className,
  path,
  hideSideBar,
  subscription,
  alwaysHideSidebar,
  setHideSideBar,
}: HeaderProps & {
  subscription: Subscriptions;
  path: string;
  alwaysHideSidebar: boolean;
  hideSideBar: boolean;
  setHideSideBar: (hideSideBar: boolean) => void;
}) {
  return (
    <div
      className="py"
      style={{
        maxWidth:
          hideSideBar || alwaysHideSidebar ? "100vw" : "calc(100vw - 180px)",
      }}
    >
      <Badge
        variant={"outline"}
        className={cn(
          "pl-lg w-full h-12 border-r rounded-none flex bg-gray-100 justify-start items-center font-medium text-sm",
          className
        )}
      >
        {alwaysHideSidebar ? (
          <Link href={"/dashboard/lists"}>
            <Button
              variant={"outline"}
              className="flex flex-row gap-sm items-center"
            >
              <ReturnIcon />
              <span>Back To Lists</span>
            </Button>
          </Link>
        ) : hideSideBar ? (
          <ChevronRightIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(false)}
          />
        ) : (
          <ChevronLeftIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(true)}
          />
        )}

        <div className="px-xl font-medium text-base">
          Settings -{" "}
          {settings.find((setting) => path.startsWith(setting.href))?.name ??
            ""}
        </div>

        <div className={"ml-auto flex flex-row gap-lg items-center"}>
          <CreditBadge subscription={subscription} />
          <FeedbackWidgetButton />
          <ProfileSettings hidePersonal={false} />
        </div>
      </Badge>
    </div>
  );
}
