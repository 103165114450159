import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/home/runner/work/lavareach/lavareach/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/home/runner/work/lavareach/lavareach/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/home/runner/work/lavareach/lavareach/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/home/runner/work/lavareach/lavareach/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/home/runner/work/lavareach/lavareach/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lavareach/lavareach/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lavareach/lavareach/node_modules/next/font/google/target.css?{\"path\":\"src/app/(app)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/lavareach/lavareach/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lavareach/lavareach/src/app/(app)/dashboard/loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lavareach/lavareach/src/app/(app)/dashboard/posthog-pageview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/home/runner/work/lavareach/lavareach/src/app/(app)/dashboard/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lavareach/lavareach/src/app/(app)/error.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lavareach/lavareach/src/app/css/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationBlockerProvider"] */ "/home/runner/work/lavareach/lavareach/src/components/functional/navigation-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardClientLayout"] */ "/home/runner/work/lavareach/lavareach/src/layout/DashboardLayout/dashboard-client-layout.tsx");
