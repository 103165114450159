"use client";

import { type Subscriptions } from "@prisma/client";
import { createContext } from "react";

export interface SubscriptionsContextProps {
  subscription: Subscriptions;
  consumeCredits: (credits: number) => void;
}

export const SubscriptionContext = createContext<
  SubscriptionsContextProps | undefined
>(undefined);
