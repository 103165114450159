export function isSnakeCase(str: string) {
  const regex = /^[a-z1-9]+(_[a-z1-9]+)*$/;
  return regex.test(str);
}

export function toSnakeCase(str: string) {
  return (
    str
      // Replace all spaces and dashes with underscores
      .replace(/\s+/g, "_")
      .replace(/-/g, "_")
      // Insert an underscore before all uppercase letters (for camelCase strings)
      // and convert the whole string to lowercase.
      .replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`)
      // Remove any leading or trailing underscores
      .replace(/^_+|_+$/g, "")
      // Avoid double underscores
      .replace(/__+/g, "_")
  );
}

export function formatListColumn(input: string) {
  input = input
    .replace(/^customTrigger-/, "")
    .replace(/^customEnrichment-/, "")
    .replace(/^contactEnrichment-/, "");

  // Capitalize the first letter of each word
  return snakeToTitleCase(input)
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function snakeToTitleCase(snake: string) {
  return snake
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function splitCommaSeperatedString({
  string,
  minLength,
}: {
  string: string;
  minLength: number;
}) {
  return string
    .split(",")
    .map((str) => str.trim())
    .filter((str) => str.length >= minLength);
}

export function trimAndFilterStrings(
  strings: string[],
  { minLength = 1 }: { minLength?: number } = {}
) {
  return strings
    .map((str) => str.trim())
    .filter((str) => str.length >= minLength);
}
